import {
   getDocument,
   GlobalWorkerOptions,
   version,
} from 'pdfjs-dist/legacy/build/pdf.mjs';
import { RefCallback, useCallback, useEffect, useState } from 'react';

GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf-${version}.worker.min.mjs`;

interface Options {
   pageContainerClass?: string;
   pageCanvasClass?: string;
   onlyFirstPage?: boolean;
}
export default function usePdf(
   url: Parameters<typeof getDocument>[0],
   options?: Options,
): RefCallback<HTMLDivElement> {
   const [container, setContainer] = useState<HTMLDivElement | null>(null);
   // Set the container to state whenever it changes
   const refCallback = useCallback(setContainer, [setContainer]);

   useEffect(() => {
      if (container && url) {
         renderPdf(url, container, options).catch(
            () => (container.innerHTML = 'Unavailable'),
         );
      }
      // Clear out container when one of them changes
      return () => {
         if (container) {
            container.innerHTML = '';
         }
      };
   }, [container, url, options?.pageContainerClass, options?.pageCanvasClass]);

   return refCallback;
}

async function renderPdf(
   url: Parameters<typeof getDocument>[0],
   container: HTMLDivElement,
   { pageContainerClass, pageCanvasClass, onlyFirstPage = false }: Options = {},
) {
   const pdf = await getDocument(url).promise;

   const numPages = onlyFirstPage ? 1 : pdf.numPages;

   // First append the divs to the DOM (sequential)
   const pageCanvases: HTMLCanvasElement[] = [];
   for (let i = 1; i <= numPages; i++) {
      const pageContainer = document.createElement('div');
      pageContainer.className = pageContainerClass ?? 'pdf-page-container';
      const pageCanvas = document.createElement('canvas');
      pageCanvas.className = pageCanvasClass ?? 'pdf-page';
      pageCanvases.push(pageCanvas);
      pageContainer.append(pageCanvas);
      container.append(pageContainer);
   }

   const CSS_UNITS = 96 / 72; // Ratio of CSS pixels to PDF points
   const devicePixelRatio = window.devicePixelRatio || 1;
   const scale = CSS_UNITS * devicePixelRatio;

   // Then render the pages to the canvases (parallel)
   pageCanvases.forEach(async (canvas, i) => {
      const page = await pdf.getPage(i + 1); // Page numbers start from 1
      const viewport = page.getViewport({ scale });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Render
      const canvasContext = canvas.getContext('2d')!;
      page.render({ canvasContext, viewport });
   });
}
